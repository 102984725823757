import React from "react";
import { graphql } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsPublishedInTheFuture,
} from "../lib/helpers";
import Layout from "../components/common/layout";
import InformationNavbar from "../components/information/information-navbar";
import ArticleList from "../components/information/article-list";
import Pagination from "../components/common/pagination";

export const query = graphql`
  query ArticlePageQuery($skip: Int!, $limit: Int!) {
    articles: allSanityArticle(
      skip: $skip
      limit: $limit
      filter: { publishedAt: { ne: "null" }, linkUrl: { ne: "null" } }
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          id
          publishedAt(locale: "id", formatString: "dddd, DD MMM YYYY")
          title
          linkUrl
          _rawExcerpt
        }
      }
    }
  }
`;

const ArticlePage = (props) => {
  const { data } = props;

  const nodes = (data || {}).articles
    ? mapEdgesToNodes(data.articles).filter(filterOutDocsPublishedInTheFuture)
    : [];

  return (
    <Layout>
      <InformationNavbar />
      {nodes && <ArticleList nodes={nodes} />}
      <Pagination
        humanPageNumber={props.pageContext.humanPageNumber}
        numberOfPages={props.pageContext.numberOfPages}
        previousPagePath={props.pageContext.previousPagePath}
        nextPagePath={props.pageContext.nextPagePath}
        paginationPath={props.pageContext.paginationPath}
      />
    </Layout>
  );
};

export default ArticlePage;
