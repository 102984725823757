import React from "react";
import PortableText from "../portableText";

const ArticlePreview = (props) => {
  return (
    <div className="pv3">
      <div className="bg-white ba b--white br3 pa2 ph5-ns">
        <span className="db f3 f2-ns display dark-gray mt4">
          {props.node.title}
        </span>
        <div className="mv3 mb4-ns flex justify-between">
          <div className="db f6 silver ttu tracked sans-serif">
            {props.node.publishedAt}
          </div>
        </div>
        <div className="serif f4 lh-copy">
          <PortableText blocks={props.node._rawExcerpt} />
        </div>
        <div className="pa2 flex justify-end serif h3">
          <a
            href={props.node.linkUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="gray hover-black-90 tracked ttu sans-serif f5"
          >
            Lihat Lebih Lanjut
          </a>
        </div>
      </div>
    </div>
  );
};

export default ArticlePreview;
