import React from "react";
import ArticlePreview from "./article-preview";

const ArticleList = (props) => {
  return (
    <div className="mw9 center mt4 mb4 ph4-ns">
      {props.nodes.map((node) => (
        <ArticlePreview key={node.title} node={node} />
      ))}
    </div>
  );
};

export default ArticleList;
